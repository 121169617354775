/*---------------------------------*/
/* ------ Configuración Endpoints Services ------*/
export const API_LICENCIAMIENTO = 'https://corelicence.gse.com.co';
export const API_SEGURIDAD = 'https://coresecurity.gse.com.co/security';
export const API_EXTERNAL_SOURCE = 'https://8gz2pd1dd2.execute-api.us-east-2.amazonaws.com/pro';
export const API_PAYMENTSERVICESVINKEL = process.env.NODE_ENV === 'production'
  ? 'https://paymentservice.ibuhoo.com/v1/'
  : 'https://paymentservice.ibuhoo.com/v1/';
export const EPAYCOPAYMENTSSERVICES = process.env.NODE_ENV === 'production'
  ? 'https://payment.gse.com.co/payment'
  : 'https://payment.gse.com.co/payment';
export const API_AUTHENTICATION = process.env.NODE_ENV === 'production'
  ? 'https://8gz2pd1dd2.execute-api.us-east-2.amazonaws.com/pro/authentication'
  : 'http://localhost:62074/authentication';

/*---------------------------------*/
/* ------ Configuración Sites ------*/
export const URL_WEB_COLFACTURA = 'https://web.colfactura.com/';
export const URL_WEB_COLNOMINA = 'https://nomina.colfactura.com/';
export const URL_WEB_FIRMAYA = 'https://web.firmaya.co/home/';
export const URL_WEB_FIRMAMAIL = 'https://web.colfactura.com/';
export const URL_WEB_SUPERGUARDA = 'https://market.superguarda.com.co/';
export const URL_WEB_IBUHOO = '/';
export const URL_SECURITY = 'https://security-licence.gse.com.co/';
export const URL_CONTACT_COLNOMINA = 'https://forms.gle/JFwN3uWqjGzX5EQaA';

/* ------ Configuración Respuestas Servicio ------*/
/**
 * Respuestas de servicio
 */
export const DEFAULTRESPONSE = {
  noResponseFromApi: 'No hay respuesta por parte del servidor. Por favor intente más tarde.',
  errorInRequest: 'El servidor no ha podido interpretar la solicitud. Por favor contacte al administrador.',
  errorRegisterPurchaseOrder: 'Se ha presentado un error al registrar tu orden de compra, Por favor intenta nuevamente.', // error en licenciamiento
  errorRegisterPaymentVinkel: 'Se ha presentado un error al proceder a realizar el pago en línea, Por favor intenta nuevamente.', // error en ecommerce vinkel
  errorDownloadFile: 'Se ha presentado un error al intentar descargar el archivo.',
};

/* ------ Configuración Paginacion ------*/
/**
 * Configuración Paginacion numero de registros
 */
export const NUM_REGISTERS_PAGING = 30;

/**
 * token prefix eg. 'Bearer ' or 'Token '
 */
export const TOKEN_PREFIX = 'Bearer ';

/**
 * Llave de encripción
 */
export const ENCRYPTION_KEY = [
  227,
  144,
  75,
  59,
  108,
  168,
  38,
  75,
  193,
  195,
  63,
  125,
  144,
  17,
  12,
  148,
  217,
  49,
  188,
  190,
  52,
  189,
  68,
  134,
  106,
  170,
  255,
  88,
  27,
  183,
  249,
  88,
];

/**
 * Iv de encripción
 */
export const ENCRYPTION_IV = [
  58,
  237,
  223,
  159,
  226,
  61,
  73,
  88,
  156,
  25,
  99,
  11,
  144,
  81,
  32,
  101,
];

/**
 * Variable booleana encriptar contenido de localstorage
 */
export const LOCAL_STORAGE_ENCRYPT = true;

/**
 * API Key Google Maps
 */
export const API_KEY_GOOGLE_MAPS = process.env.NODE_ENV === 'production'
  ? 'AIzaSyDXv5FNyZvEOqj9LmKM-K-sGPlTCY9AGvQ'
  : 'AIzaSyDXv5FNyZvEOqj9LmKM-K-sGPlTCY9AGvQ';

/**
 * Validación de pago epayco
 */
export const VALIDATE_PAYMENT_EPAYCO = false;

/**
 * Segundos para validación de pago
 */
export const SECONDS_VALIDATE_PAYMENT = '30';

/**
 * URL Reporte Gestión Servicio
 */
export const URL_REPORT_GESTION_SERVICIO = 'https://consultaLicense:D-HsrVPtI]jd_UDhp|Z7yYIm3)wd)e@rsgsecontrol.gse.com.co:60443/Reports/report/SuiteGSE%20License/Mesa%20Servicio/Gesti%C3%B3n%20Servicio';

/**
 * URL Reporte Soporte Cliente
 */
export const URL_REPORT_SOPORTE_CLIENTE = 'https://consultaLicense:D-HsrVPtI]jd_UDhp|Z7yYIm3)wd)e@rsgsecontrol.gse.com.co:60443/Reports/report/SuiteGSE%20License/Mesa%20Servicio/Soporte%20por%20Cliente';
/**
 * URL Reporte Códigos Promocionales
 */
export const URL_REPORT_CODIGOS_PROMOCIONALES = 'https://consultaLicense:D-HsrVPtI]jd_UDhp|Z7yYIm3)wd)e@rs.colfactura.com:55443/ReportServer/Pages/ReportViewer.aspx?%2fSuiteGSE+License%2fOperaci%c3%b3n%2fUso+C%c3%b3digos+Promocionales&rc:Toolbar=true&rs:ClearSession=True';
